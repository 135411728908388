import App from "./app";

// ************************************
// SCSSのコンバート処理
// ************************************
import "../styles/style.scss";

// ************************************
// Javascript記述開始
// ************************************

const app = new App();