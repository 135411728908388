import { getViewportMode } from '../util/getdata';
import { gsap, Power3, Linear } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const elem = '.js-firstview-visual';

const imgItem = {
  data : 'data-fv-visual',
  cloud : 'cloud',
  fill : 'fill',
  tablet : 'tablet',
  icons : 'icons',
  wave : 'wave',
  hand : 'hand',
  light : 'light',
}

const initFirstview = () => {

  const setVisualSize = () => {
    const element = document.querySelector(elem);
  
    if(element) {
      const elemWidth = element.clientWidth;
      const elemHeight = element.clientHeight;
      const vp = getViewportMode();
  
      const setElementWidth = () => {
        const blankY = window.innerHeight - elemHeight;
        const height = elemHeight + blankY;
        const rate = (16 / 9);
        const width = Math.floor(height * rate);
  
        if(width >= window.innerWidth) {
          element.classList.add('is-set');
          element.style.width = `${width}px`;
        } else {
          element.classList.remove('is-set');
          element.removeAttribute('style');
        }
      }
  
      const removeElementWidth = () => {
        if(element.classList.contains('is-set')) {
          element.classList.remove('is-set');
          element.removeAttribute('style');
        }
      }
  
      if(vp.device != 'sp') {
        if((elemHeight < window.innerHeight) || (elemWidth < window.innerWidth)) {
          setElementWidth();
        }
      } else {
        removeElementWidth();
      }
  
    }
  }

  const initImages = () => {
    const element = document.querySelector(elem);

    if(element) {
      const media = gsap.matchMedia();
      const breakpoint = 768;
      const scrollOpt = {
        trigger: elem,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        // markers: true,
      }

      const mouseStalker = () => {
        const fill = document.querySelector(`[${imgItem.data}=${imgItem.fill}]`);
        const hand = document.querySelector(`[${imgItem.data}=${imgItem.hand}]`);
        const activeArea = document.querySelector('.p-firstview');

        // ウィンドウの中心の取得
        const xCenter = window.innerWidth / 2;
        const yCenter = window.innerHeight / 2;

        // パララックスで移動させる距離
        const parallaxVal = 40; //中心からマウスの距離の10分１移動

        // マウスがパララックスエリアに入った時のイベント
        activeArea.addEventListener('mousemove', (e) => {
          // マウスの座標を取得して 中心からの距離取得、 そこからpararaxValで距離を調整
          const x = (xCenter - e.pageX) / parallaxVal;
          const y = (yCenter - e.pageY) / parallaxVal;

          const vp = getViewportMode();

          // パララックスさせる要素にstyleの指定
          if(vp.device == 'pc') {
            fill.style.transform = `translate(${-x}px, ${-y}px)`;
            hand.style.transform = `translate(${-x}px, ${-y}px)`;
          }
        });
      }

      media.add({
        isDesktop    : `(min-width: ${breakpoint}px)`,
        isMobile     : `(max-width: ${breakpoint - 1}px)`,
        reduceMotion : '(prefers-reduced-motion: reduce)',
      }, (context) => {
        let { isDesktop, isMobile, reduceMotion } = context.conditions;

        // gsap.to(`[${imgItem.data}=${imgItem.hand}]`, {
        //   x: isDesktop ? 50 : 0,
        //   ease: Power3.easeOut,
        //   scrollTrigger: scrollOpt,
        // });

        gsap.to(`[${imgItem.data}=${imgItem.icons}]`, {
          y: isDesktop ? -40 : 0,
          ease: Power3.easeOut,
          scrollTrigger: scrollOpt,
        });
        gsap.to(elem, {
          filter: 'blur(10px)',
          ease: Linear.none,
          scrollTrigger: scrollOpt,
        });
      });
    }
  }

  setVisualSize();
  // initImages();

  window.addEventListener('resize', () => {
    setVisualSize();
  }, { passive: false });

}

export const initVisualTransition = () => {
  const html    = document.documentElement;
  const element = document.querySelector(elem);

  if( element ) {
    const tl = gsap.timeline({
      delay: 1,
      defaults: {
        duration: 0.5,
        ease: Power3.easeOut,
      }
    });
    const elemReadTexts = document.querySelector('.p-firstview__read').querySelectorAll('p');
    const elemReadText_01 = elemReadTexts[0].querySelector('span');
    const elemReadText_02 = elemReadTexts[1].querySelector('span');
    const elemReadText_03 = elemReadTexts[2].querySelector('span');

    const elemHand = document.querySelector(`[${imgItem.data}=${imgItem.hand}]`);

    const elemName = document.querySelector('.p-firstview__catch');

    html.classList.add('visual-start-translated');

    gsap.set(elemReadText_01, { yPercent: 100, opacity: 0 });
    gsap.set(elemReadText_02, { yPercent: 100, opacity: 0 });
    gsap.set(elemReadText_03, { yPercent: 100, opacity: 0 });
    gsap.set(elemName, { opacity: 0, filter: 'blur(10px)' });
    gsap.set(element, { opacity: 0 });
    gsap.set(elemHand, { opacity: 0, xPercent: 10, yPercent: 10 });

    tl.to(elemReadText_01, { yPercent: 0, opacity: 1 });
    tl.to(elemReadText_02, { yPercent: 0, opacity: 1 }, "<+0.3");
    tl.to(elemReadText_03, { yPercent: 0, opacity: 1 }, "<+0.3");
    tl.to(elemName, { opacity: 1, filter: 'blur(0)' });
    tl.add(() => { html.classList.add('visual-before-translated') }, "<");
    tl.to(element, { opacity: 1, duration: 1.5 });
    tl.to(elemHand, { opacity: 1, xPercent: 0, yPercent: 0, duration: 1 }, "<+0.5");
  }
}

export default initFirstview;