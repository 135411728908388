import Swiper, { Pagination, Navigation } from 'swiper';
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

/**
 * ニュースコンテンツ
 */
const initNews = () => {
  const sectionElem = '.p-news';
  const listElem = {
    main: '.js-news-list',
    prev: '.js-news-list-prev',
    next: '.js-news-list-next',
  }
  const itemElem    = '.js-news-item';
  const detailElem  = '.js-news-detail';
  const contentElem = '.js-news-content';
  const articleElem = '.js-news-article';
  const closeElem   = '.js-news-close';

  /**
   * リストスライダー
   */
  const initList = () => {
    const element = document.querySelector(listElem.main);

    if(element) {
      const swiper = new Swiper(listElem.main, {
        modules: [Pagination, Navigation],
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: listElem.next,
          prevEl: listElem.prev,
        },
        breakpoints: {
          768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          1050: {
            slidesPerView: 2.5,
            spaceBetween: 15,
          },
        },
      });
    }
  }

  /**
   * 詳細モーダル
   */
  const initItem = () => {
    const items = document.querySelectorAll(itemElem);
    const detail = document.querySelector(detailElem);

    if(items.length && detailElem) {
      const html     = document.querySelector('html');
      const content  = detail.querySelector(contentElem);
      const close    = detail.querySelector(closeElem);
      const articles = detail.querySelectorAll(articleElem);

      for(const item of items) {
        const class_ready = 'news-ready';
        const class_open  = 'news-opened';
        let moving_flg = false;

        const setContent = (id) => {
          for(const article of articles) {
            const data_id = article.getAttribute('data-news-id');

            if(data_id == id) {
              article.style.display = 'block';
            } else {
              article.style.display = 'none';
            }
          } 
        }

        const detailOpen = (id) => {
          if(!html.classList.contains(class_open) && !(moving_flg) && id) {
            new Promise((resolve) => {
              moving_flg = true;
              setContent(id);
              html.classList.add(class_ready);
              detail.scrollTop = 0;

              setTimeout(() => {
                resolve();
              }, 50);
            }).then(() => {
              html.classList.add(class_open);
              moving_flg = false;
            });
          }
        }

        const detailClose = () => {
          if(html.classList.contains(class_open) && !(moving_flg)) {
            new Promise((resolve) => {
              moving_flg = true;
              html.classList.remove(class_open);

              setTimeout(() => {
                resolve();
              }, 1000);
            }).then(() => {
              html.classList.remove(class_ready);
              moving_flg = false;
            });
          }
        }

        item.addEventListener('click', () => {
          const id = item.getAttribute('data-news-id');

          if(!html.classList.contains(class_open) && id) {
            detailOpen(id);
          } else if(html.classList.contains(class_open) && id) {
            detailClose();
          }
        });

        close.addEventListener('click', () => {
          detailClose();
        });

        detail.addEventListener('click', () => {
          detailClose();
        });

        content.addEventListener('click', (e) => {
          e.stopPropagation();
        })
      }
    }
  }

  /**
   * コンテンツ背景
   */
  const setBackgroundTranslate = () => {
    const section = document.querySelector(sectionElem);

    if(section) {
      gsap.to(':root', {
        '--news-bg-object-translate': '100px',
        ease: Power3.easeOut,
        scrollTrigger: {
          trigger: sectionElem,
          start: 'top center',
          end: 'bottom top',
          scrub: true,
          // markers: true,
        },
      });
    }
  }

  initList();
  initItem();
  setBackgroundTranslate();
}

export default initNews;