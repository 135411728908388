import { getScrollTop } from '../util/getdata';

/**
 * onScreenの準備
 */
export const initScreenReady = () => {
  const screen = '.js-screen';
  const readyClassName = 'on-screen';
  const targets = document.querySelectorAll(screen);

  if(targets.length) {
    for(const target of targets) {
      target.classList.add(readyClassName);
    }
  }

  setTextSpan();
  setScreen();
}

/**
 * 見出し用 テキストをspan囲み
 */
export const setTextSpan = () => {
  const elems = document.querySelectorAll('.js-screen-text');
  const doneClassName = 'is-set';

  if(elems.length) {
    for(const elem of elems) {
      if( elem.classList.contains(doneClassName) ) continue;

      const inner = elem.firstElementChild;
      const text = inner.textContent;
      const arr_text = [...text];
      let delay = 0;

      const setText = () => {
        inner.style.display = 'none';

        const wrapper = document.createElement('span');
        elem.appendChild(wrapper);

        for(const set_text of arr_text) {
          delay += 100;

          if(set_text == ' ') {
            set_text = '&nbsp;';
          }

          wrapper.innerHTML += `<span style="transition-delay: ${delay}ms">${set_text}</span>`;
        }

        elem.classList.add(doneClassName);
      }

      setTimeout(setText, 50);
    }
  }
}

export const setScreen = () => {
  const screen          = '.js-screen';
  const readyClassName  = 'on-screen';
  const activeClassName = 'is-screen';

  const targets = document.querySelectorAll(screen);

  const options = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: 0,
  }

  const setClassName = ( entries ) => {
    entries.forEach( entry => {
      if(entry.isIntersecting && entry.target.classList.contains(readyClassName)) {
        entry.target.classList.add(activeClassName);
      }
    });
  }

  const observer = new IntersectionObserver(setClassName, options);

  if(targets.length) {
    const arrTarget = Array.from(targets);
    arrTarget.forEach( target => {
      observer.observe(target);
    });
  }
}
