import { headerMenuClose } from './header';

const elem = '.js-menu-list';

const initMenuList = () => {
  const elements = document.querySelectorAll(elem);

  if(elements.length) {
    for(const element of elements) {
      const items = element.querySelectorAll('a');

      for(const item of items) {
        const thisList = item.closest(elem);

        item.addEventListener('mouseover', () => {
          if(!thisList.classList.contains('is-menu-active')) {
            thisList.classList.add('is-menu-active');
          }

          if(!item.classList.contains('is-current')) {
            for(const item_remove of items) {
              item_remove.classList.remove('is-current');
            }

            item.classList.add('is-current');
          }
        });

        item.addEventListener('mouseleave', () => {
          if(thisList.classList.contains('is-menu-active')) {
            thisList.classList.remove('is-menu-active');
          }

          if(item.classList.contains('is-current')) {
            item.classList.remove('is-current');
          }
        });

        item.addEventListener('click', (e) => {
          headerMenuClose();
        });
      }
    }
  }
}

export const menuChangeLinks = ( mode = 'home' ) => {
  const elements = document.querySelectorAll(elem);

  if(elements.length) {
    const arrElement = Array.from(elements);

    arrElement.forEach( element => {
      const anchors = element.querySelectorAll('a');

      if(anchors.length) {
        const arrAnchor = Array.from(anchors);

        arrAnchor.forEach( anchor => {
          const href = anchor.getAttribute('href');

          if(mode == 'home') {
            const reg_with_slash = /^\/#/;

            if(reg_with_slash.test(href)) {
              const modifiedHref = href.replace(reg_with_slash, '#');
              anchor.setAttribute('href', modifiedHref);
            }
          } else {
            const reg_no_slash   = /^#/;

            if(reg_no_slash.test(href)) {
              const modifiedHref = `/${href}`;
              anchor.setAttribute('href', modifiedHref);
            }
          }
        });
      }
    });
  }
}

export default initMenuList;