import barba from "@barba/core";
import gsap from "gsap";

import {
  initPageLoading,
  initExtarnalSet
} from "./module/initialize";

import initHeader from "./module/header";
import initMenuList, { menuChangeLinks } from "./module/menu";
import initFirstview from "./module/firstview";
import { initWorksList } from "./module/works";
import initNews from "./module/news"
import { initGourmetList, initGourmetDetail, resetGourmetDetail } from "./module/gourmet";
import initAbout from "./module/about";
import { initScreenReady } from './module/screen';

export default class App {
  constructor() {
    this.init();
  }

  init() {
    /**
     * MEMO:
     * パスで判別する場合
     * const path = location.pathname;
     * 
     * // "/directory/"の判別
     * if(path.match(/^\/directory\/$/)) {
     *   // ...
     * }
     */

    const setMouseRectToSession = () => {
      const links = document.querySelectorAll('a');
      const arrLink = Array.from(links);

      arrLink.forEach( link => {
        link.addEventListener('click', (e) => {
          const clickX = e.clientX;
          const clickY = e.clientY;
          sessionStorage.setItem('clickPosition', JSON.stringify({ x: clickX, y: clickY }));
        });
      });
    }

    const replaceHeadTags = target => {
      const head = document.head;
      const targetHead = target.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
      const newPageHead = document.createElement('head');
      newPageHead.innerHTML = targetHead;
      const removeHeadTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='fb']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[name='robots']",
        'meta[itemprop]',
        'link[itemprop]',
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']"
      ].join(',');

      const arrCurRemoveHeadTags = Array.from(head.querySelectorAll(removeHeadTags));
      const arrNewRemoveHeadTags = Array.from(newPageHead.querySelectorAll(removeHeadTags));

      const headTags = [...arrCurRemoveHeadTags];
      headTags.forEach(item => {
        head.removeChild(item);
      });

      const newHeadTags = [...arrNewRemoveHeadTags];
      newHeadTags.forEach(item => {
        head.appendChild(item);
      });
    }

    const delay = ( ms ) => {
      const time = ms || 2000;
      return new Promise( done => {
        setTimeout( () => {
          done('');
        }, time);
      });
    }

    const leaveAnimation = (data) => {
      const html   = document.documentElement;
      const target = document.querySelector('.l-loading');

      const clickPosition = JSON.parse(sessionStorage.getItem('clickPosition'));

      if( clickPosition ) {
        const elem = document.createElement('div');
        elem.classList.add('l-loading__point');

        target.appendChild(elem);
      }

      if( html.classList.contains('visual-start-translated') ) {
        html.classList.remove('visual-start-translated');
        html.classList.remove('visual-before-translated');
      }

      if( clickPosition ) {
        const elem = document.querySelector('.l-loading__point');

        const tl = gsap.timeline();

        gsap.set(target, {
          opacity: '1',
          scaleY: '1',
          background: 'transparent',
        });
        gsap.set(elem, {
          display: 'block',
          width: '1px',
          height: '1px',
          margin: 'auto',
          background: '#fff',
          borderRadius: '100vw',
          translateX: '-50%',
          translateY: '-50%',
          transformOrigin: 'center center',
          // scale: '0',
          position: 'absolute',
          top: `${clickPosition.y}px`,
          left: `${clickPosition.x}px`,
        });

        if( window.innerWidth < 768 ) {
          // sm
          tl.to(elem, {
            width: '200vh',
            height: '200vh',
            ease: 'power3.easeOut',
          });
        } else {
          // else
          tl.to(elem, {
            width: '200vw',
            height: '200vw',
            ease: 'power3.easeOut',
          });
        }
        tl.to(target, {
          background: '#fff',
        });
      } else {
        gsap.set(target, {
          opacity: '1',
        });
          gsap.to(target, {
          scaleY: '1',
          duration: 0.5,
          ease: 'power3.easeOut',
        });
      }
    }

    const enterAnimation = (data) => {
      initPageLoading();
      initScreenReady();
    }

    const arrDinamicPage = [
      /^\/gourmet\/(.*)\/(.*)\/$/,
    ];

    // barba設定
    barba.init({
      debug: true,
      // sync: true,
      // prevent: ({ el }) => el.getAttribute('href').slice(0,1) === '#',
      // prevent: ({ el }) => el.classList && el.classList.contains('js-prevent'),
      timeout: 50000,
      transitions: [
        {
          name: 'default-transition',
          once(data){
            initExtarnalSet();
            initPageLoading(data);
            initScreenReady();
            initHeader();
            initMenuList();
            setMouseRectToSession();
          },
          async leave (data) {
            // return leaveAnimation(data);
            leaveAnimation(data);
            await delay(500);
          },
          beforeEnter ({ next }) {
            // headの中身を入れ替え
            replaceHeadTags(next);
          },
          enter (data) {
            initExtarnalSet();
            setMouseRectToSession();

            // ページ表示時のアニメーション
            enterAnimation(data);
          },
        }
      ],
      cacheIgnore: [...arrDinamicPage],
      prefetchIgnore: [...arrDinamicPage],
      views: [
        {
          namespace: "home",
          beforeEnter (data) {
            document.documentElement.setAttribute('data-page', 'home');
            menuChangeLinks('home');

            initFirstview();
            initWorksList();
            initNews();
            initGourmetList();
            initAbout();
          },
        },
        {
          namespace: "works",
          beforeEnter (data) {
            document.documentElement.setAttribute('data-page', 'works');
            menuChangeLinks('works');
          },
        },
        {
          namespace: "gourmet",
          beforeEnter (data) {
            document.documentElement.setAttribute('data-page', 'gourmet');
            menuChangeLinks('gourmet');
            resetGourmetDetail();
          },
          afterEnter () {
            initGourmetDetail();
          },
        },
      ],
    });
  }
}
