import imagesLoaded from 'imagesloaded';
import gsap from 'gsap';

import { initVisualTransition } from './firstview';

import { getOffset } from '../util/getdata';

const options = {
  // no-smoothが付いている要素は無視
  ignore: '.no-smooth',
  // 1000pxのスクロールにかかる時間
  speed: 500,
  // 距離に関係なくspeedの時間で遷移させる
  speedAsDuration: true,
  // URLを更新するか否か
  updateURL: false,
  easing: 'easeOutQuint',
}

const classname = {
  loading: 'page-loading',
  loaded: 'page-loaded',
}

/**
 * ページ読み込み時の処理
 * @void
 */
export const initPageLoading = () => {
  const html = document.documentElement;

  const setLoading = () => {
    const countBorder = 200000;
    let   countLimit;

    html.classList.add(classname.loading);

    const imgLoad = new imagesLoaded( html, { background: true } );
    const countImages = imgLoad.images.length;

    // countBorderの設定値だけ画像を読み込んだらページ展開
    if( countImages > 1 ) {
      if( countImages > countBorder ) {
        countLimit = countBorder;
      } else {
        // 画像数が設定値以下の場合、ページ内半分の画像数を読み込んだらページ展開
        // countLimit = Math.floor(countImages  / 2);

        countLimit = countImages;
      }

      imgLoad.on('progress', ( e ) => {
        const loadedPercent = getLoadedPercent(e.progressedCount, countLimit);

        if((countLimit == e.progressedCount) || (countLimit == 0)) {
          initPageLoaded();
        }
      });
    } else {
      initPageLoaded();
    }
  }

  const getLoadedPercent = (progressCount, countLimit) => {
    let loadedPercent = Math.floor(progressCount / countLimit * 100);

    if(loadedPercent >= 100) {
      loadedPercent = 100;
    }

    return loadedPercent;
  }

  const initPageLoaded = () => {
    const html = document.documentElement;
    const loading = document.querySelector('.l-loading');

    html.classList.remove(classname.loading);
    html.classList.add(classname.loaded);

    gsap.to(loading, {
      scaleY: '0',
      duration: 0.5,
      ease: 'power3.easeIn',
    });
    gsap.set(loading, {
      opacity: '1',
    })

    toHashPosition();
    initVisualTransition();
  }
  setLoading();
}

/**
 * スムーススクロールの起動
 * @void
 */
export const initSmoothScroll = () => {
  const headerElem = '.js-header';

  const initModule = () => {
    const scroll = new SmoothScroll();

    const element = '[href^="#"]:not(.no-smooth)';
    const elems = document.querySelectorAll(element);

    const header = document.querySelector(headerElem);

    let headerHeight = 0;

    if(header) {
      headerHeight = header.clientHeight;

      window.addEventListener('resize', () => {
        headerHeight = header.clientHeight;
      });
    }

    // ページ内リンクの処理
    for(const elem of elems) {
      elem.addEventListener('click', (e) => {
        let anchor;
        const toggle = e.target.closest(element);

        if (toggle.hash == "#") {
          anchor = 0;
        } else if(toggle.hash) {
          anchor = document.querySelector(toggle.hash).offsetTop - headerHeight;
        } else {
          anchor = 0;
        }

        e.preventDefault();
        scroll.animateScroll(anchor, document.querySelector(element), options);
      });
    }

  }

  initModule();
  setScrollToHash();
}

/**
 * ハッシュの位置までスクロール
 * @void
 */
export const setScrollToHash = () => {
  // const scroll = new SmoothScroll();
  const header = document.querySelector('.js-header');
  let headerHeight = 0;

  if(location.hash) {
    const targets = document.querySelectorAll(location.hash);

    if(targets.length) {
      if(header) {
        headerHeight = header.clientHeight;
      }

      for(const target of targets) {
        const anchor = target.offsetTop - headerHeight;

        // scroll.animateScroll(anchor, options);
        window.scrollTo({ top: anchor, behavior: 'instant' });
        break;
      }
    }
  }
}

/**
 * ハッシュの位置まで移動
 * @void
 */
export const toHashPosition = () => {
  const header = document.querySelector('.js-header');

  if(location.hash) {
    const href = location.hash;
    const idName = href.replace('#', '');
    const target = document.getElementById(idName);

    if( target ) {
      const offset = getOffset(target);
      const pos = offset - header.clientHeight;
      window.scrollTo({ top: pos, behavior: 'instant' });
    }
  } else {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }
}

/**
 * no-jsタグの削除
 * @void
 */
export const removeNoJS = () => {
  const html = document.documentElement;
  html.classList.remove('no-js');
}

/**
 * 外部リンクにnoreferrerを追加
 * @void
 */
export const initExtarnalSet = () => {
  const elements = document.querySelectorAll('a[target="_blank"]');

  if(elements.length) {
    for(const element of elements) {
      if(!element.hasAttribute('rel')) {
        element.setAttribute('rel', 'noreferrer');
      }
    }
  }
}
