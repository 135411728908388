import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const initAbout = () => {
  const sectionElem = '.p-about';
  const mapElem     = '.p-about-map';

  const element = document.querySelector(mapElem);

  if(element) {
    gsap.from(mapElem, {
      y: -50,
      ease: Power3.easeOut,
      scrollTrigger: {
        trigger: sectionElem,
        start: 'top center',
        end: 'bottom top',
        scrub: true,
        // markers: true,
      },
    });
  }
}

export default initAbout;