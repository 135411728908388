import { getViewportMode } from '../util/getdata'; 
import Swiper, { EffectFade } from 'swiper';

export const initWorksList = () => {
  let main, list, swiperbool;
  const elem = {
    main : '.js-works-main',
    list : '.js-works-list',
  }

  const element = document.querySelector(elem.main);

  if(element) {
    const listElement = document.querySelector(elem.list);

    const createList = () => {
      list = new Swiper(elem.list, {
        slidesPerView: 5,
        spaceBetween: 20,
      });
    }

    const initList = () => {
      const vp = getViewportMode();
      const listItems = listElement.querySelectorAll('[data-item-id]');

      if(vp.device == 'pc') {
        swiperbool = false;
      } else {
        createList();
        swiperbool = true;
      }

      for(const listItem of listItems) {
        listItem.classList.add('is-active');

        return false;
      }
    }

    const setList = () => {
      const vp = getViewportMode();

      if(vp.device == 'pc' && swiperbool) {
        list.destroy(false, true);
        swiperbool = false;
      } else if(vp.device != 'pc' && !(swiperbool)) {
        createList();
        swiperbool = true;
      }
    }

    main = new Swiper(elem.main, {
      modules: [EffectFade],
      effect: 'fade',
      speed: 1000,
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true,
      },
    });

    const dataItems = listElement.querySelectorAll('[data-item-id]');

    for(const dataItem of dataItems) {
      const changeSlide = () => {
        const id = dataItem.getAttribute('data-item-id');

        for(const item of dataItems) {
          if(item.classList.contains('is-active')) {
            item.classList.remove('is-active');
          }
        }

        if(!dataItem.classList.contains('is-active')) {
          dataItem.classList.add('is-active');
          main.slideTo(id);
        }
      }

      dataItem.addEventListener('mouseover', () => {
        const vp = getViewportMode();

        if(vp.device == 'pc') {
          changeSlide();
        }
      }, { passive: false });

      dataItem.addEventListener('click', () => {
        const vp = getViewportMode();

        if(vp.device != 'pc') {
          changeSlide();
        }
      })
    }

    initList();
    window.addEventListener('resize', setList, { passive: false });
  }
}
