/**
 * @fileOverview データ取得系関数
 * 
 * - getBrowser
 * - getDevice
 * - getViewportWidth
 * - getScrollTop
 * - getViewportMode
 */

/**
 * 閲覧ブラウザを判別して取得
 * 
 * @return { String } IE, Edge, Firefox, Chrome, Opera, Safari, other
 */
export const getBrowser = () => {
  const ua = window.navigator.userAgent;
  let res = 'other';

  // IE
  if(ua.match(/MSIE/) || ua.match(/Trident/)) {
    res = 'IE';

  // Edge
  } else if(ua.indexOf("Edg") > -1) {
    res = 'Edge';

  // Firefox
  } else if(ua.indexOf("Firefox") > -1) {
    res = 'Firefox';

  // Chrome
  } else if(ua.indexOf("Chrome") > -1) {
    res = 'Chrome';

  // Opera
  } else if(ua.indexOf("Opera") > -1) {
    res = 'Opera';

  // Safari
  } else if(ua.indexOf("Safari") > -1) {
    res = 'Safari';
  }

  return res;
}

/**
 * 閲覧デバイスを判別して取得
 * 
 * @return { String } iPhone, iPad, Android, Mobile, other
 */
export const getDevice = () => {
  const ua = window.navigator.userAgent;
  let res = 'other';

  if( ua.indexOf('iPhone') > -1 ) {
    res = 'iPhone';
  } else if( ua.indexOf('iPad') > -1 ) {
    res = 'iPad';
  } else if( ua.indexOf('Android') > -1 ) {
    res = 'Android';
  } else if(
    ua.indexOf('iPhone') == -1 &&
    ua.indexOf('iPad') == -1 &&
    ua.indexOf('Android') == -1 &&
    ua.indexOf('Mobile') == -1
  ) {
    res = 'pc';
  }

  return res;
}

/**
 * 画面の幅を取得
 * 
 * @return { Number } viewport width
 */
export const getViewportWidth = () => {
  return window.innerWidth;
}

/**
 * 現在のスクロール位置を取得
 * 
 * @returns { Number } scrolltop
 */
export const getScrollTop = () => {
  const res = window.pageYOffset || document.documentElement.scrollTop
  return res;
}

/**
 * 要素の位置を取得
 * 
 * @params { HTMLElement } element
 * @params { String } position ['top':default, 'right', 'bottom', 'left']
 * @return { Number } offset
 */
export const getOffset = ( element, position = 'top' ) => {
  const rect = element.getBoundingClientRect();
  const scrollTop = getScrollTop();
  let res = 0;

  switch (position) {
    case 'top': 
      res = rect.top + scrollTop;
      break;

    case 'bottom':
      res = rect.bottom + scrollTop;
      break;

    case 'left':
      res = rect.left;
      break;

    case 'right':
      res = rect.right;
      break;

    default:
      console.error('getOffset: positionの値が正しくありません');
      break;
  }

  return res;
}

/**
 * 画面モードを取得
 * 
 * @returns {{ mode: string, device: string }}[mode: 'lg' device: 'pc']
 */
export const getViewportMode = () => {
  let res = {
    mode : 'lg',
    device : 'pc',
  }

  const setting = {
    sm : 768,
    md : 1050,
  }

  const viewport = getViewportWidth();

  if(viewport >= setting.md) {
    res.mode = 'lg';
    res.device = 'pc';
  } else if(viewport >= setting.sm) {
    res.mode = 'md';
    res.device = 'tablet';
  } else {
    res.mode = 'sm';
    res.device = 'sp';
  }

  return res;
}
