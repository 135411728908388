import Swiper, { Navigation, FreeMode } from 'swiper';

export const initGourmetList = () => {
  const elem = {
    main : '.js-gourmet-list',
    prev : '.js-gourmet-list-prev',
    next : '.js-gourmet-list-next',
  }
  const element = document.querySelector(elem.main);

  if(element) {
    const swiper = new Swiper(elem.main, {
      modules: [Navigation, FreeMode],
      slidesPerView: 2.6,
      freeMode: true,
      spaceBetween: 10,
      navigation: {
        nextEl: elem.next,
        prevEl: elem.prev,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1050: {
          slidesPerView: 4.25,
        },
      }
    });
  }
}

const elemDetail = {
  main: '.js-gourmet-detail-main',
  sub: '.js-gourmet-detail-sub',
  prev: '.js-gourmet-detail-main__prev',
  next: '.js-gourmet-detail-main__next',
}

const optionMain = () => {
  return {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: elemDetail.next,
      prevEl: elemDetail.prev,
    },
    on : {
      slideChange : () => {
        changeActiveThumb(window.detailSlide);
      }
    },
  }
}

const initMainSlider = (selector) => {
  const options = optionMain();
  return new Swiper(selector, options);
}

const changeActiveThumb = (detailSlide) => {
  const selector = elemDetail.sub;
  const element = document.querySelector(selector);

  window.thumbItems = element.querySelectorAll('[data-gourmet-index]');

  const arrItem = Array.from(window.thumbItems);
  const slideIndex = detailSlide.activeIndex;

  if (detailSlide) {
    arrItem.forEach((item) => {
      const data = item.getAttribute('data-gourmet-index');

      if (Number(data) === slideIndex) {
        item.classList.add('is-active');
      } else {
        item.classList.remove('is-active');
      }
    });
  }
}

const thumbClickHandler = (event) => {
  const dataIndex = event.currentTarget.getAttribute('data-gourmet-index');
  if (window.detailSlide) {
    window.detailSlide.slideTo(Number(dataIndex));
    changeActiveThumb(window.detailSlide);
  }
}

export const initGourmetDetail = () => {
  if (!window.detailSlide && document.querySelector(elemDetail.main)) {
    window.detailSlide = initMainSlider(elemDetail.main, optionMain);
  }

  const selector = elemDetail.sub;
  const element = document.querySelector(selector);

  if (element && document.querySelector(elemDetail.main)) {
    window.thumbItems = element.querySelectorAll('[data-gourmet-index]');
    const arrItem = Array.from(window.thumbItems);

    arrItem.forEach((item) => {
      if (item.getAttribute('data-gourmet-index') === '0') {
        item.classList.add('is-active');
      }

      item.addEventListener('click', thumbClickHandler);
    });
  }
}

export const resetGourmetDetail = () => {
  console.log('execReset');
  const selector = elemDetail.sub;
  const element = document.querySelector(selector);

  if (window.detailSlide && document.querySelector(elemDetail.main) && element) {
    window.thumbItems = element.querySelectorAll('[data-gourmet-index]');
    const arrItem = Array.from(window.thumbItems);

    arrItem.forEach((item) => {
      item.removeEventListener('click', thumbClickHandler);
    });

    window.detailSlide.destroy();
    window.detailSlide = null;
  }
}
