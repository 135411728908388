import gsap from 'gsap';
import { getScrollTop } from '../util/getdata';

const html = document.documentElement;

const class_ready = 'menu-ready';
const class_open  = 'menu-opened';

const initHeader = () => {
  setFixed();
  setMenu();
}

const setFixed = () => {
  const elem = '.js-header';
  const element = document.querySelector(elem);

  if(element) {
    const elemHeight = element.clientHeight;
    let scrolltop = getScrollTop();
    let mode = null;
    let movingFlg = false;

    const classname_ready = 'header-fixed-ready';
    const classname_fixed = 'header-fixed';

    let limit;

    const setLimit = () => {
      const index = '.p-firstview';
      const lower = '.p-firstview-lower';

      const elems_index = document.querySelectorAll(index);
      const elems_lower = document.querySelectorAll(lower);

      let targets;
      let int = 0;
      let mode;

      if(elems_index.length || elems_lower.length) {
        if(elems_lower.length) {
          targets = elems_lower;
          mode = 'lower';
        } else if(elems_index.length) {
          targets = elems_index;
          mode = 'index';
        }
      }

      if(targets.length) {
        for(const target of targets) {
          const offset = target.offsetTop;
          const pos = offset + target.clientHeight;
          int = pos;

          if(mode == 'index') {
            int = int / 2;
          }

          break;
        }
      } else {
        int = 300;
      }

      return int;
    }

    const setFixed = () => {
      scrolltop = getScrollTop();
      limit = setLimit();

      if(scrolltop >= elemHeight) {
        if(!html.classList.contains(classname_ready) && !(movingFlg)) {
          new Promise((resolve) => {
            html.classList.add(classname_ready);
            element.style.display = 'none';
            movingFlg = true;

            setTimeout(() => {
              resolve();
            }, 5);
          }).then(() => {
            element.style.display = 'block';
            movingFlg = false;
          });
        }
      } else {
        if(html.classList.contains(classname_ready) && !(movingFlg)) {
          html.classList.remove(classname_ready);
        }
      }

      if(scrolltop >= limit && html.classList.contains(classname_ready) && !(movingFlg)) {
        html.classList.add(classname_fixed);
      } else {
        html.classList.remove(classname_fixed);
      }
    }

    window.addEventListener('scroll', setFixed, { passive: false });
  }
}

const setMenu = () => {
  const elem = {
    main    : '.js-header-menu',
    trigger : '.js-header-menu-trigger',
  }

  const element = document.querySelector(elem.main);
  const triggers = document.querySelectorAll(elem.trigger);

  if(element && triggers.length) {

    const anchors = element.querySelectorAll('a');

    for(const trigger of triggers) {
      trigger.addEventListener('click', () => {
        headerMenuToggle();
      });
    }

    if(anchors.length) {
      for(const anchor of anchors) {
        anchor.addEventListener('click', () => {
          const href = anchor.getAttribute('href');

          if(href.charAt(0) == '#') {
            headerMenuClose();
          }
        });
      }
    }
  }
}

export const headerMenuOpen = async () => {
  const headerMenu = document.querySelector('.js-header-menu');

  if(!html.classList.contains(class_open)) {
    gsap.to( headerMenu, {
      display   : 'block',
      visibility: 'visible',
      opacity   : 1,
      duration  : 0.3,
    });
    await html.classList.add(class_open);

    // const menu = async () => {
    //   html.classList.add(class_ready);
    //   await html.classList.add(class_open);
    // }
    // menu();
    // new Promise((resolve) => {

    //   setTimeout(() => {
    //     resolve();
    //   }, 50);
    // }).then(() => {
    // });
  }
}

export const headerMenuClose = async () => {
  const headerMenu = document.querySelector('.js-header-menu');

  if(html.classList.contains(class_open)) {
    gsap.to( headerMenu, {
      display   : 'none',
      visibility: 'hidden',
      opacity   : 0,
      duration  : 0.3,
    });
    await html.classList.remove(class_open);
    // const menu = async () => {
    //   html.classList.remove(class_open);
    //   await html.classList.remove(class_ready);
    // }
    // menu();
    // new Promise((resolve) => {
    //   html.classList.remove(class_open);

    //   setTimeout(() => {
    //     resolve();
    //   }, 1000);
    // }).then(() => {
    //   html.classList.remove(class_ready);
    // });
  }
}

export const headerMenuToggle = () => {
  if(!html.classList.contains(class_open)) {
    headerMenuOpen();
  } else {
    headerMenuClose();
  }
}

export default initHeader;